export { matchers } from './client-matchers.js';

			export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')];

			export const server_loads = [];

			export const dictionary = {
	"(app)": [4,[2]],
	"(app)/dashboard": [5,[2]],
	"(app)/signin": [6,[2]],
	"(app)/verify": [7,[2]],
	"case": [8,[3]],
	"deployment": [9],
	"license": [10],
	"ltitoken": [11],
	"unregistered": [12]
};

			export const hooks = {
				handleError: (({ error }) => { console.error(error); return { message: 'Internal Error' }; }),
			};